import { CheckCircleIcon } from "@heroicons/react/24/solid";
import getConfig from "next/config";
import Image from "next/image";
const { publicRuntimeConfig } = getConfig();

const { NEXT_PUBLIC_CDN_URL } = publicRuntimeConfig;

type FraudPreventionAnimationProps = {
  title: string;
  subtitle: string;
  helper: string;
};

export default function FraudPreventionAnimation({
  title,
  subtitle,
  helper,
}: FraudPreventionAnimationProps) {
  return (
    <div>
      <div className="text-3xl font-bold text-center md:text-4xl 3xl:text-5xl">
        {title}
      </div>
      <div className="mb-8 text-3xl font-bold text-center md:text-4xl 3xl:text-5xl">
        {subtitle}
      </div>
      <div className="text-lg font-medium text-center 3xl:text-xl">
        {helper}
      </div>
      <div className="relative w-full h-full mx-auto mt-4 md:mt-8">
        {" "}
        {/* <AutoPlaySilentVideo
          className="object-cover rounded-2xl"
          video={`${NEXT_PUBLIC_CDN_URL}/landing_page/video/risk-fuel-management-platform.mp4`}
        ></AutoPlaySilentVideo> */}
        <div className="flex flex-row">
          <div className="w-1/3 py-8 pr-2 md:pr-4 justify-self-end">
            <div className="flex flex-col justify-between h-full">
              <div className="flex flex-row items-center justify-end animate-opacity">
                <div className="mr-2 text-[8px] md:text-xs font-medium lg:text-xl text-right">
                  Transaction Controls
                </div>
                <CheckCircleIcon className="w-5 h-5 mr-3 min-w-[20px] min-h-[20px] md:min-w-[40px] md:min-h-[40px] lg:min-w-[50px] lg:min-h-[50px] stroke-[3px] text-orange-200" />
              </div>
              <div className="flex flex-row items-center justify-end animate-opacity_slow">
                <div className="mr-2 text-[8px] md:text-xs font-medium lg:text-xl text-right">
                  User Validation
                </div>
                <CheckCircleIcon className="w-5 h-5 mr-3 min-w-[20px] min-h-[20px] md:min-w-[40px] md:min-h-[40px] lg:min-w-[50px] lg:min-h-[50px] stroke-[3px] text-orange-200" />
              </div>
              <div className="flex flex-row items-center justify-end animate-opacity_very_slow">
                <div className="mr-2 text-[8px] md:text-xs font-medium lg:text-xl text-right">
                  Fuel Validation
                </div>
                <CheckCircleIcon className="w-5 h-5 mr-3 min-w-[20px] min-h-[20px] md:min-w-[40px] md:min-h-[40px] lg:min-w-[50px] lg:min-h-[50px] stroke-[3px] text-orange-200" />
              </div>
            </div>
          </div>
          <div className="w-1/3">
            <Image
              src={`${NEXT_PUBLIC_CDN_URL}/landing_page/images/fuel-fraud.png`}
              priority={true}
              layout="responsive"
              width={500}
              height={500}
              alt="blockquote"
            />
          </div>
          <div className="w-1/3 pl-2 md:pl-4 justify-self-end">
            <div className="flex flex-col justify-between h-full py-8 animate-opacity">
              <div className="flex flex-row items-center justify-start">
                <CheckCircleIcon className="w-5 h-5 mr-3 min-w-[20px] min-h-[20px] md:min-w-[40px] md:min-h-[40px] lg:min-w-[50px] lg:min-h-[50px] stroke-[3px] text-orange-200" />
                <div className="mr-2 text-[8px] md:text-xs font-medium lg:text-xl text-left">
                  Retroactive Fuel Analysis
                </div>
              </div>
              <div className="flex flex-row items-center justify-start animate-opacity_slow">
                <CheckCircleIcon className="w-5 h-5 mr-3 min-w-[20px] min-h-[20px] md:min-w-[40px] md:min-h-[40px] lg:min-w-[50px] lg:min-h-[50px] stroke-[3px] text-orange-200" />
                <div className="mr-2 text-[8px] md:text-xs font-medium lg:text-xl text-left">
                  Location Validation
                </div>
              </div>
              <div className="flex flex-row items-center justify-start animate-opacity_very_slow">
                <CheckCircleIcon className="w-5 h-5 mr-3 min-w-[20px] min-h-[20px] md:min-w-[40px] md:min-h-[40px] lg:min-w-[50px] lg:min-h-[50px] stroke-[3px] text-orange-200" />
                <div className="mr-2 text-[8px] md:text-xs font-medium lg:text-xl text-left">
                  Fuel Type Validation
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
