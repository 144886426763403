/** @format */

import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { StarIcon } from "@heroicons/react/24/solid";
import {
  Button,
  ButtonSize,
  ButtonVariant,
} from "@roadflex/react-components-www";
import getConfig from "next/config";
import Image from "next/image";
import { useRouter } from "next/router";
import { useEffect, useRef } from "react";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_CDN_URL } = publicRuntimeConfig;

export function AutoPlaySilentVideo(props) {
  const videoRef = useRef(undefined);
  useEffect(() => {
    videoRef.current.defaultMuted = true;
  });
  return (
    <video
      className={props.className}
      ref={videoRef}
      loop
      autoPlay
      muted
      playsInline
    >
      <source src={props.video} type="video/mp4" />
    </video>
  );
}

export default function Panel1() {
  const router = useRouter();

  return (
    <div id="panel-1" className="w-full">
      <div className="relative text-white bg-gradient-to-r from-brown-900 to-brown-700 h-[100vh]">
        <div className="relative flex flex-row items-center h-full text-left md:w-4/6 animate-reverseping">
          <div
            className="sm:h-[50%] h-full w-full flex items-center justify-center px-2"
            style={{ zIndex: "2" }}
          >
            <div className="flex flex-col w-[100%] md:w-[60%] text-center md:text-left">
              <div className="text-2xl font-semibold animate-slidein md:text-3xl lg:text-4xl 3xl:text-6xl">
                Proactive Expense and<br></br>Fuel Risk Management
              </div>
              <div className="my-4 font-normal lg:text-lg 3xl:text-2xl animate-slidein">
                <div>
                  One place to manage all your expenses, automate fuel
                  management and optimize fleet and financial reporting
                </div>
              </div>
              <div className="flex flex-row items-center justify-center pt-3 md:justify-start">
                <div className="flex flex-row items-center mr-1">
                  <Image
                    layout="fixed"
                    width={"120"}
                    height={"40"}
                    className=""
                    src={`${NEXT_PUBLIC_CDN_URL}/landing_page/svg/capterra.svg`}
                    alt="capterra logo"
                  ></Image>
                  <StarIcon className="w-4 h-4 ml-2 text-yellow-500 sm:w-7 sm:h-7" />
                  <StarIcon className="w-4 h-4 text-yellow-500 sm:w-7 sm:h-7" />
                  <StarIcon className="w-4 h-4 text-yellow-500 sm:w-7 sm:h-7" />
                  <StarIcon className="w-4 h-4 text-yellow-500 sm:w-7 sm:h-7" />
                  <StarIcon className="w-4 h-4 text-yellow-500 sm:w-7 sm:h-7" />
                </div>
                <div className="ml-2 text-sm md:text-lg">customer reviews</div>
              </div>
              <div className="flex flex-row items-center justify-center pt-3 text-sm font-normal text-center md:pt-5 md:text-left md:justify-start md:text-lg">
                <div className="flex flex-col gap-2">
                  <div className="mr-4">
                    <div className="flex flex-row items-center">
                      <div className="flex items-center justify-center">
                        <CheckCircleIcon className="mr-3 text-orange-500 w-7 h-7 md:w-7 md:h-7 min-w-[30px] min-h-[30px] stroke-[3px]"></CheckCircleIcon>
                      </div>
                      <div className="pl-1">
                        Visa Business Card, accepted everywhere
                      </div>
                    </div>
                  </div>
                  <div className="mr-4">
                    <div className="flex flex-row items-center">
                      <div className="flex items-center justify-center">
                        <CheckCircleIcon className="mr-3 text-orange-500 w-7 h-7 md:w-7 md:h-7 min-w-[30px] min-h-[30px] stroke-[3px]"></CheckCircleIcon>
                      </div>
                      <div className="pl-1">
                        Competitive Discounts & Rebates
                      </div>
                    </div>
                  </div>
                  <div className="mr-4">
                    <div className="flex flex-row items-center">
                      <div className="flex items-center justify-center">
                        <CheckCircleIcon className="mr-3 text-orange-500 w-7 h-7 md:w-7 md:h-7 min-w-[30px] min-h-[30px] stroke-[3px]"></CheckCircleIcon>
                      </div>
                      <div className="pl-1">
                        Dedicated customer success, 7-day support
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row justify-center mt-10 md:items-center md:justify-start">
                <span>
                  <Button
                    variant={ButtonVariant.Orange}
                    onClick={() => {
                      router.push({
                        pathname: "/contact-us",
                      });
                    }}
                    className="!px-4 sm:!px-8 font-medium"
                    size={ButtonSize.Large}
                  >
                    Schedule a Demo
                  </Button>
                </span>
                <span className="ml-4">
                  <Button
                    variant={ButtonVariant.GrayOutlineWhiteText}
                    onClick={() => {
                      router.push({
                        pathname: "/signup",
                      });
                    }}
                    className="!px-4 sm:!px-8 font-medium"
                    size={ButtonSize.Large}
                  >
                    Apply Now
                  </Button>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          className="absolute bottom-0 flex justify-center w-full lg:block"
          style={{ zIndex: "1" }}
        >
          <AutoPlaySilentVideo
            className="object-cover min-h-screen opacity-40"
            video={`${NEXT_PUBLIC_CDN_URL}/landing_page/video/black-video.mp4`}
          ></AutoPlaySilentVideo>
        </div>
      </div>
    </div>
  );
}
