/** @format */
import classNames from "classnames";
import getConfig from "next/config";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_CDN_URL } = publicRuntimeConfig;
export default function Panel10() {
  const data: {
    id: number;
    name: string;
    imageLink: string;
    text1: string;
    text2: string;
    nameLineBreak: boolean;
  }[] = [
    {
      id: 1,
      name: "Fleet Managers",
      nameLineBreak: true,
      imageLink: `${NEXT_PUBLIC_CDN_URL}/landing_page/images/fleet_team/fleet-manager.png`,
      text1:
        "Put your fleet expense management efforts on Autopilot with our automation, analytics and reporting.",
      text2: "",
    },
    {
      id: 2,
      name: "Finance & Accounting Managers",
      nameLineBreak: false,
      imageLink: `${NEXT_PUBLIC_CDN_URL}/landing_page/images/fleet_team/finance-manager.png`,
      text1:
        "Close your books 4x faster. Receive digital receipts and details for every transaction.",
      text2: "",
    },
    {
      id: 3,
      name: "Fleet Owners and Executives",
      nameLineBreak: false,
      imageLink: `${NEXT_PUBLIC_CDN_URL}/landing_page/images/fleet_team/fleet-owner.png`,
      text1:
        "Have full visibility into every dollar spent. Set spending rules and policies, and reduce costs for your business.",
      text2: "",
    },
    {
      id: 4,
      name: "Safety Managers",
      nameLineBreak: true,
      imageLink: `${NEXT_PUBLIC_CDN_URL}/landing_page/images/fleet_team/safety-manager.png`,
      text1:
        "Eliminate card misuse and fuel fraud/theft with our Proactive Fuel Risk Management Platform™.",
      text2: "",
    },
    {
      id: 5,
      name: "Drivers and other teammates",
      nameLineBreak: false,
      imageLink: `${NEXT_PUBLIC_CDN_URL}/landing_page/images/fleet_team/driver.png`,
      text1:
        "One card for all fuel, fleet and field expenses, with automated expense reports and digital receipts.",
      text2: "",
    },
  ];
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (!sectionRef.current) return;

      const topPosition = sectionRef.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (topPosition < windowHeight * 0.9) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <div id="panel-10" className="w-full bg-lightyellow py-14 md:py-32 ">
      <div className=" text-brown-900 container  2xl:max-w-[1750px!important]">
        <div className="">
          <div className="mb-8 md:m-16">
            <div
              ref={sectionRef}
              className={`transition-all duration-700 ease-in-out transform mb-4 text-3xl font-bold text-start md:text-center md:text-4xl 3xl:text-5xl ${
                isVisible
                  ? "translate-x-0 opacity-100"
                  : "-translate-x-40 opacity-0"
              }`}
            >
              RoadFlex partners with everyone on your team
            </div>
            {/* <div className="text-base text-center">{subHeader}</div> */}
          </div>
          <Swiper
            modules={[Autoplay]}
            speed={4000}
            breakpoints={{
              1500: { slidesPerView: 5, slidesPerGroup: 1, spaceBetween: 20 },
              // 1500: { slidesPerView: 3.5, slidesPerGroup: 1, spaceBetween: 12 },
              // 1350: { slidesPerView: 5, slidesPerGroup: 1, spaceBetween: 12 },
              998: { slidesPerView: 3.6, slidesPerGroup: 1, spaceBetween: 20 },
              625: { slidesPerView: 2.5, slidesPerGroup: 1, spaceBetween: 12 },
              425: { slidesPerView: 1.5, slidesPerGroup: 1, spaceBetween: 12 },
              0: { slidesPerView: 1.2, slidesPerGroup: 1, spaceBetween: 12 },
            }}
            autoplay={{ delay: 0 }}
            lazyPreloadPrevNext={3}
            loop={true}
            className="child:!ease-linear"
          >
            {data.length > 0 &&
              data.map((fleetIndustry, i: number) => (
                <SwiperSlide key={fleetIndustry.id}>
                  <div className="flex flex-col rounded-md bg-lightyellow">
                    <div
                      className={classNames(`h-[full]`, "relative mb-4 w-full")}
                    >
                      <Image
                        src={fleetIndustry.imageLink}
                        layout="responsive"
                        width={200}
                        height={215}
                        loading="eager"
                        // height={i % 2 === 0 ? "300px" : "180px"}
                        alt="RoadFlex Partners"
                        className="rounded-lg"
                      />
                    </div>
                    <div className="mt-1">
                      <div className="text-2xl font-bold text-black whitespace-pre-wrap">
                        {fleetIndustry.nameLineBreak ? (
                          <>
                            {fleetIndustry.name.split(" ")[0]} <br />
                            {fleetIndustry.name.split(" ")[1]}
                          </>
                        ) : (
                          fleetIndustry.name
                        )}
                      </div>
                    </div>
                    <div className="h-full mt-3 flex-grow-1">
                      {fleetIndustry.text1}
                    </div>
                    <div className="h-full mt-1flex-grow-1">
                      {fleetIndustry.text2}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
